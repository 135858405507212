export const shortenString = (string, words) => {
  return string.split(' ').splice(0, words).join(' ');
}

const containsUppercase = (str) => {
  return /[A-Z]/.test(str)
}

const containsLowercase = (str) => {
  return /[a-z]/.test(str)
}

const containsNumber = (str) => {
  return /[0-9]/.test(str)
}

const containsSpecialSymbol = (str) => {
  return /[!@#$%^&*()\-+={}[\]:;"'<>,.?/|\\]/.test(str)
}

const domainEnSymbols = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', ]

const domainKaSymbols = ['ა', 'ბ', 'გ', 'დ', 'ე', 'ვ', 'ზ', 'თ', 'ი', 'კ', 'ლ', 'მ', 'ნ', 'ო', 'პ', 'ჟ', 'რ', 'ს', 'ტ', 'უ', 'ფ', 'ქ', 'ღ', 'ყ', 'შ', 'ჩ', 'ც', 'ძ', 'წ', 'ჭ', 'ხ', 'ჯ', 'ჰ', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', ]

export const validateDomainName = (domain, language) => {
  let symbols = []
  if (language === 'en') symbols = domainEnSymbols
  if (language === 'ka') symbols = domainKaSymbols
  
  const domainArray = domain.split('')
  let result = true
  
  domainArray.forEach(item => {
    if (!symbols.includes(item)) result = false
  })

  return result
}

export const isValidEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const isValidPhone = (phone) => {
  return /^[0-9\b]+$/.test(phone)
}

const isValidNumber = (number) => {
  return /^[0-9]+$/.test(number)
}

export const isValidPassword = (str) => {
  return containsUppercase(str) && containsLowercase(str) && containsNumber(str) && containsSpecialSymbol(str) && str.length > 7
}

export const isValidPersonalId = (str) => {
  return isValidNumber(str) && str.length === 11
}

export const isValidCompanyId = (str) => {
  return isValidNumber(str) && str.length === 9
}