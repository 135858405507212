export const sortByPriority = (list) => {
  return [...list].sort((a, b) => a.priority - b.priority)
}

export const sortByStartDate = (list) => {
  return [...list].sort((a, b) => a.start_date < b.start_date ? 1 : -1)
}

export const sortByTitle = (list, lang) => {
  return [...list].sort((a, b) => a.title[lang] > b.title[lang] ? 1 : -1)
}